import React, { useState,useEffect } from 'react';
import { daysBetween, formatDate, formatRate, getGradeRange } from '../../constants/helpers';
import { SVGs } from '../../constants/Svgs';
import { GymMinimalProps, RankingLineProps, RankingTabOneChallengeProps, RankingTabProps, RoutesDisplayInfoProps } from '../../constants/types';
import { fetchRouteImage } from '../../data/getAxiosRequests';
import ColorSquare from '../UI/ColorSquare';
import CustomButton from '../UI/CustomButton';
import DifficultySvg from '../UI/DifficultySvg'
import IconButton from '../UI/IconButton';
import StarRating from '../UI/StarRating';




const RankingLine : React.FC<RankingLineProps> = ({ firstName,lastName,points,rank,shoulBeTransformed, noPoints }) => {
    const svgName = `rank${rank}`;
    const RankIcon = SVGs[svgName];
    const initialLastName = lastName ? lastName.charAt(0).toUpperCase() : "A";
    function formatPoints(points : number) {
        const multipliedPoints = points *1000

        
      
        if (multipliedPoints < 10000) {
          return multipliedPoints.toFixed(0);
        } else if (multipliedPoints < 100000) {
          return (multipliedPoints / 1000).toFixed(1) + ' K';
        } else if (multipliedPoints < 1000000) { // Corrected this range for 100,000 to 999,999
          return (multipliedPoints / 1000).toFixed(0) + ' K';
        } else if (multipliedPoints < 1000000000) { // For 1,000,000 to 999,999,999
          return (multipliedPoints / 1000000).toFixed(2) + ' M';
        } else if (multipliedPoints < 10000000000) { // Corrected this range for 1,000,000,000 to 9,999,999,999
          return (multipliedPoints / 1000000000).toFixed(2) + ' M'; // Keep two decimal places for clarity
        } else if (multipliedPoints < 100000000000) { // For 10,000,000,000 to 99,999,999,999
          return (multipliedPoints / 1000000000).toFixed(1) + ' M';
        } else { // For 100,000,000,000 and above
          return (multipliedPoints / 1000000000).toFixed(0) + ' M';
        }
    }

    return (
        
        <div className='ranking_line_container' style={rank !=1 ? {paddingTop:'4px',paddingBottom:'4px'}: {paddingTop:'8px', paddingBottom:'8px'}}>
            
                <>{!noPoints?
                    <div className="table_line__rank">
                        {rank && rank ==1 ? 
                            <IconButton 
                                Svg={RankIcon}
                            />
                            :
                            <div className='rank_icon_written bold lower2'>#{rank}</div>
                        }
                    </div>:null
                    }
                    <div className="table_line__name" >
                        <p className='light lower'>{firstName} {initialLastName}.</p>
                    </div>
                    {!noPoints?
                    <div className="table_line__points" >
                        {shoulBeTransformed ?
                        <p className='regular normal'>{formatPoints(points)}</p>
                        :
                        <p className='regular normal'>{points}</p>
                        }
                    </div>
                    :null
                    }
                </>
            
        </div>
        
        

   
    )
}

const RankingTabOneChallenge : React.FC<RankingTabOneChallengeProps> = ({shouldPointsBeTransformed, lines,category,shouldShowPoints}) => {

    return (
        <div className='ranking_table_container'>
            
            <div className='ranking_section'> 
                {shouldShowPoints ? 
                    <div className='ranking_section_header'>
                        <div className="regular normal table_line__rank" ><span style={{marginLeft:'8px'}}>#</span></div>
                        <div className="regular normal table_line__name" >Athlète</div>
                        <div className="regular normal table_line__points" >Points</div>
                    </div>
                :
                    <div className='ranking_section_header'>
                        <div className="regular normal table_line__rank" ><span style={{marginLeft:'8px'}}>Participants</span></div>
                    </div>
                }
                {(lines).map((line, index) => (
                    <RankingLine
                        key={index} // It's important to provide a unique key for each child in a list
                        firstName={line.firstName}
                        lastName={line.lastName}
                        points={line.points}
                        rank={line.rank}
                        shoulBeTransformed={shouldPointsBeTransformed}
                        noPoints={!shouldShowPoints}
                    />
                ))}
                
                
            </div>
            
        </div>
        

   
    )
}


export default RankingTabOneChallenge